<template>
  <div style="text-align: justify">
    <h2 class="mb-4">Kinetics - Prelab Exercise</h2>

    <p class="mb-2">
      Please enter your exercise number below. Without this number, you will automatically receive a
      grade of 0 on your prelab. If this does not match the number on the prelab you hand in, you
      will receive a grade of 0.
    </p>

    <calculation-input
      v-model="inputs.exerciseNumber"
      class="mb-5"
      prepend-text="$\text{Exercise Number:}$"
      :disabled="!allowEditing"
    />

    <div class="mb-3" v-if="exNumber >= 101 && exNumber <= 900">
      <p class="mb-5">
        a) Using a first order plot, determine the rate constant,
        <stemble-latex content="$\text{k}_1,$" /> for the oxidation of benzyl alcohol with potassium
        permanganate at the temperature, <stemble-latex content="$\text{T}_1=$" />
        <latex-number :number="T1.toFixed(1)" unit="^\circ\text{C,}" /> from the information in the
        table below. (Molar absorptivity,
        <stemble-latex content="$\varepsilon=2350~\text{M}^{-1}\text{cm}^{-1}$" />; path length,
        <stemble-latex content="$\ell=1.00\,\text{cm.}$" />
      </p>

      <v-simple-table style="width: 300px" class="pl-6 mb-6" dense>
        <tbody>
          <tr>
            <td style="vertical-align: middle">
              <stemble-latex content="$\textbf{Time (min) }$" />
            </td>
            <td style="vertical-align: middle">
              <stemble-latex content="$\textbf{Absorbance}$" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle">
              <stemble-latex content="$15.0$" />
            </td>
            <td style="vertical-align: middle">
              <latex-number :number="abs15" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle">
              <stemble-latex content="$30.0$" />
            </td>
            <td style="vertical-align: middle">
              <latex-number :number="abs30" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle">
              <stemble-latex content="$45.0$" />
            </td>
            <td style="vertical-align: middle">
              <latex-number :number="abs45" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle">
              <stemble-latex content="$60.0$" />
            </td>
            <td style="vertical-align: middle">
              <latex-number :number="abs60" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle">
              <stemble-latex content="$75.0$" />
            </td>
            <td style="vertical-align: middle">
              <latex-number :number="abs75" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-3">i) What is the value of the rate constant at this temperature?</p>

      <v-row>
        <v-col cols="5">
          <calculation-input
            v-model="inputs.k1"
            prepend-text="$\text{k}_1:$"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="inputs.k1Units"
            prefix="Units:"
            outlined
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>

      <p class="mb-5">
        ii) Please upload your graph as a single image file using the input field below.
      </p>

      <file-upload-ui-component
        style="width: 100%"
        label="Click here to upload a file"
        :max-number-of-files="1"
        :maxFileSize="20"
        accepted-file-extensions=".png, .webp, .jpg, .jpeg"
        @upload:attachments="attachments = $event"
      />

      <p class="mb-5">
        b) Using a second rate constant, <stemble-latex content="$\text{k}_2=$" />
        <latex-number :number="k2.toFixed(4)" unit="\text{min}^{-1}" />
        at the temperature,
        <latex-number :number="T2.toFixed(1)" unit="^\circ\text{C,}" />
        determine the activation energy,
        <stemble-latex content="$\text{E}_{\text{a}},$" />
        for the oxidation of benzyl alcohol with potassium permanganate (Gas constant, R =
        <stemble-latex content="$\text{R}=8.314~\text{J}\cdot\text{K}^{-1}\text{mol}^{-1}.$" />
      </p>

      <calculation-input
        v-model="inputs.Ea"
        prepend-text="$\text{E}_\text{a}$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </div>

    <div v-else>
      <span style="font-weight: bold; color: #c50000">Please enter a Valid Exercise Number</span>
    </div>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import NumberValue from '../NumberValue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';

export default {
  name: 'UPEIKineticsPrelabV2',
  components: {
    FileUploadUiComponent,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
    LatexNumber,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        exerciseNumber: null,
        k1: null,
        k1Units: null,
        Ea: null,
      },
      attachments: [],
    };
  },
  computed: {
    exNumber() {
      if (this.inputs.exerciseNumber) {
        return this.inputs.exerciseNumber;
      } else {
        return 0;
      }
    },
    k1() {
      return seededRandomBetween(this.exNumber, 0.0199, 0.029, 4);
    },
    concA0() {
      return seededRandomBetween(this.exNumber, 0.000475, 0.0005, 6);
    },
    rowR() {
      return seededRandomBetween(this.exNumber * 3, 34, 56, 6);
    },
    T1() {
      return this.k1 * 2.3 + 19.8;
    },
    T2() {
      return seededRandomBetween(this.exNumber * 5, 37, 39, 1);
    },
    abs15() {
      return (2350 * this.concA0 * Math.exp(-this.k1 * 15)).toFixed(3);
    },
    abs30() {
      return (2350 * this.concA0 * Math.exp(-this.k1 * 30)).toFixed(3);
    },
    abs45() {
      return (2350 * this.concA0 * Math.exp(-this.k1 * 45)).toFixed(3);
    },
    abs60() {
      return (2350 * this.concA0 * Math.exp(-this.k1 * 60)).toFixed(3);
    },
    abs75() {
      return (2350 * this.concA0 * Math.exp(-this.k1 * 75)).toFixed(3);
    },
    rowS() {
      return this.k1 / Math.exp((-this.rowR * 1000) / 8.314 / (this.T1 + 273.15));
    },
    k2() {
      return this.rowS * Math.exp((-this.rowR * 1000) / 8.314 / (this.T2 + 273.15));
    },
  },
};
</script>
